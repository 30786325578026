<template>
  <div class="not-found">
    <h1>Oh no! You Rolled a One</h1>
    <p>Welcome to the portal to the darkest timeline.</p>
    <router-link to="/">Go Back Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style>
.not-found {
  text-align: center;
  padding: 50px;
}
</style>
