<script setup>

</script>

<template>
  <div class="background-container">
    <div class="overlay-text">
      <h1 class="main-heading">Non-Grouper</h1>
      <h2 class="sub-header">A Community themed website that is as pointless as your degree.</h2>
    </div>
  </div>
</template>

<style scoped>
.background-container {
  background-image: url('@/assets/community-background.png');
  background-size: cover;
  background-position: center;
  height: 100vh; /* Sets the height to 100% of the viewport */
  position: relative; /* Establishes a positioning context for the overlay text */
}

.background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the text */
  color: white;
  text-align: center;
  margin: 0; /* Removes default margins */
}

.main-heading {
  font-size: 4rem; /* Adjust the font size as needed */
  color: white;
  text-align: center;
  margin: 0;
}

.sub-header {
  font-size: 2rem; /* Adjust the font size as needed */
  color: white;
  text-align: center;
  margin: 1rem 0 0;
}
</style>
