import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

const app = createApp(App);

// Function to initialize Google Analytics
function addGoogleAnalytics() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-6HTX93KR86';
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-6HTX93KR86');
  `;
    document.head.appendChild(inlineScript);
}

// Call the function to add the script when the app loads
addGoogleAnalytics();

// Track page views on route change
router.afterEach((to) => {
    if (window.gtag) {
        window.gtag('config', 'G-6HTX93KR86', {
            page_path: to.fullPath,
        });
    }
});

app.use(router).mount('#app');
